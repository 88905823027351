import React from 'react'
import Layout from '../layouts'

const NotFoundPage = () => (
	<Layout head={{ title: '404 not found' }}>
		<div className="container mt-5 py-5">
			<h1 className="mt-5">NOT FOUND</h1>
			<p>Page is not defined</p>
		</div>
	</Layout>
)

export default NotFoundPage
